import React from "react"
import BuildingIcon from "../images/building-icon.png"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import css from "@emotion/css"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
export default function BusinessLoanTemplate({ data: { products } }) {
  return (
    <>
      <Seo
        data={{
          title: products.seo && products.seo.title,
          description: products.seo && products.seo.description,
        }}
      ></Seo>
      <div className="border-b border-gray-300">
        <div className="container py-6">
          <h2 className="text-3xl font-semibold text-center ">
            {products.title}
          </h2>
        </div>
      </div>
      <div className="bg-brand-gray">
        <div className="container py-10 flex flex-wrap ">
          {products.products.map((product, i) => (
            <div className="  w-full sm:w-1/2 px-3 ">
              {" "}
              <div key={i} className=" p-8">
                <h3 className="text-blue-800 text-2xl text-center font-semibold mb-2">
                  {product.title}
                </h3>
                <div
                  className="text-brand-dark "
                  css={css`
                    a {
                      margin-top: 1rem;
                      display: inline-block;
                      font-weight: semibold;
                    }
                  `}
                >
                  {documentToReactComponents(product.description.json)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  {
    products: contentfulOtherProducts {
      title
      seo {
        title
        description
      }
      products {
        title
        description {
          json
        }
      }
    }
  }
`
